import React from "react";
import "../styles/Header.css"; // You'll create this for styling
import me from "../assets/my-picture.jpg";

const titleStyle = {
  color: "white",
  margin: 0,
  fontSize: "2.5rem",
};
  


const subTitleStyle = {
  color: "#FFC436",
  margin: 0,
  fontSize: "1.5rem",
  // marginLeft: "1rem",
};

function Header() {
  return (
    <div className="header">
      <img src={me} alt="Profile" className="picture" />
      <div cla ssName="header-text">
         <div style={titleStyle}>Oleg Buklemishev</div>
        <div style={subTitleStyle}>-Software engineer</div>
      </div>
    </div>
  );
}

export default Header;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../styles/ItemDetail.css'; // Replace with your actual CSS file path

function ItemDetail() {
  let { id } = useParams();
  
  return (
    <div>
      <Link to="/" className="return-button">Return to Main Page</Link>

      <h2>Item Detail Page</h2>
      <p>Showing details for item: {id}</p>
      {/* Here you can fetch and display more details based on the item ID */}
    </div>
  );
}

export default ItemDetail;

import React from 'react';
import '../styles/Sidebar.css';  // You'll create this for styling

function Sidebar() {
    return (
        <div className="sidebar">
            <h3 style={{ color: "white" }}>About</h3>
            <p>
  With a <span style={{ fontWeight: "bold", color: "white" }}>Bac +5 degree in computer science</span> and 
  <span style={{ fontWeight: "bold", color: "white" }}> 2 years of experience</span> as a versatile software engineer, 
  I've delved into <span style={{ fontWeight: "bold", color: "white" }}>web, mobile, and blockchain development.</span> <br />My skills in React, React Native, Java, C, Python, backend technologies, and Solidity 
  have been put to the test in a variety of projects. <br />From tackling algorithmic challenges 
  and exploring theoretical computer science to experimenting with AI, I bring a comprehensive 
  and agile approach to every challenge.
</p>

            

      <h3 style={{ color: "white", fontWeight: "bold" }}>Skills</h3>

            <div style={{ textAlign: "center" }}>
      <ul style={{ columnCount: 2, columnGap: "20px", color: "white"}}>
      <li>Cloud</li>
      <li>Blockchain</li>
      
        <li>Databases</li>
        <li>JavaScript</li>
        <li>AI</li>

        <li>React</li>
        <li>HTML</li>
        <li>CSS</li>
        <li>Java</li>
        <li>C</li>
        <li>Python</li>
        <li>React Native</li>
        <li>Solidity</li>        

      </ul>
    </div>


            

    <h3 style={{ color: "white" }}>Contact</h3>
            <p>buklemishevoleg@gmail.com</p>
            <p>06 58 64 00 32</p>
            
            {/* <!-- You can add other footer details or links here --> */}
        </div>
    );
}

export default Sidebar;
